<div class="flex items-center justify-between mb-16">
  <div class="heading-s flex">
    @if (iconUrl) {
      <img
        [ngSrc]="iconUrl"
        class="mr-24"
        alt="Equipment"
        height="36"
        width="32"
      />
    }
    {{ title }}
  </div>
</div>

<ie-breadcrumbs class="flex mb-32"></ie-breadcrumbs>
