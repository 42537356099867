import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  NotificationResponseDto,
  UpdateNotificationInAppStatusDto,
} from 'shared-general-libs/dto/notification';
import { InAppStatus } from 'shared-general-libs/type/notification';
import { NotificationService } from '@ieRoot/app/core/services/notification.service';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { IeCardComponent } from '@ieShared/components/ie-card/ie-card.component';
import { NzColDirective, NzRowDirective } from 'ng-zorro-antd/grid';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { TimeagoModule } from 'ngx-timeago';
import { IeAlertComponent } from '@ieShared/components/ie-alert/ie-alert.component';

@Component({
  selector: 'app-ie-notification-list',
  standalone: true,
  imports: [
    TranslateModule,
    CommonModule,
    IeCardComponent,
    NzColDirective,
    NzRowDirective,
    NzAvatarModule,
    IeAlertComponent,
    TimeagoModule,
  ],
  templateUrl: './ie-notification-list.component.html',
  styleUrl: './ie-notification-list.component.scss',
})
export class IeNotificationListComponent {
  @Input() notifications: NotificationResponseDto[] = [];
  @Input() title: string = '';
  @Output() getNotifications = new EventEmitter<string>();
  constructor(private notificationService: NotificationService) {}
  markAsRead(notification?: NotificationResponseDto) {
    const payload: UpdateNotificationInAppStatusDto = {
      inappStatus: InAppStatus.READ,
    };

    const markRequest =
      notification && notification.id
        ? this.notificationService.markAsRead(notification?.id, payload)
        : this.notificationService.markAsReadAll(payload);
    markRequest.response.subscribe(() => {
      this.getNotifications.emit();
    });
  }
}
