import { Component, Input } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { IeBreadcrumbsComponent } from '@ieShared/components/ie-breadcrumbs/ie-breadcrumbs.component';

@Component({
  selector: 'app-page-header',
  standalone: true,
  imports: [NgOptimizedImage, TranslateModule, IeBreadcrumbsComponent],
  templateUrl: './page-header.component.html',
  styleUrl: './page-header.component.scss',
})
export class PageHeaderComponent {
  @Input() title: string = '';
  @Input() iconUrl: string = '';
}
