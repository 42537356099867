<app-page-header
  [title]="'PAGE_TITLE.NOTIFICATION' | translate"
  iconUrl="assets/icons/notification/notification.svg"
></app-page-header>

<div class="flex mt-32">
  <ie-card>
    <div card-title class="flex flex-1 justify-between items-center">
      <div class="title-m fw-600">{{ 'NOTIFICATION.TITLE' | translate }}</div>
      <ie-button size="sm" (click)="markAsRead()">{{
        'NOTIFICATION.MARK_ALL_READ' | translate
      }}</ie-button>
    </div>

    <div card-body>
      <div class="divider mr-16 ml-16"></div>
      <nz-tabset nzType="card" class="ml-16 mt-16 mr-16">
        @for (tab of tabs; track tab) {
          <nz-tab [nzTitle]="tab" (nzClick)="getNotifications(tab)"> </nz-tab>
        }
      </nz-tabset>
      <app-ie-notification-list
        [notifications]="notificationsToday"
        [title]="'NOTIFICATION.TODAY' | translate"
      ></app-ie-notification-list>
      <app-ie-notification-list
        [notifications]="notificationsYesterday"
        [title]="'NOTIFICATION.YESTERDAY' | translate"
      ></app-ie-notification-list>
      <app-ie-notification-list
        [notifications]="notifications"
        [title]="'NOTIFICATION.LAST_WEEK' | translate"
      ></app-ie-notification-list>
      @if (noDataFlag) {
        <div class="mr-16 mb-16 ml-16">
          <ie-alert [title]="'NOTIFICATION.NO_NOTIFICATION' | translate"
            >{{ 'NOTIFICATION.NO_NOTIFICATION_DESCRIPTION' | translate }}
          </ie-alert>
        </div>
      }
    </div>
  </ie-card>
</div>
