import { Component, OnInit } from '@angular/core';
import { CommonModule, DatePipe, NgOptimizedImage } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { IeBreadcrumbsComponent } from '@ieShared/components/ie-breadcrumbs/ie-breadcrumbs.component';
import { IeButtonComponent } from '@ieShared/components/ie-button/ie-button.component';
import { IeCardComponent } from '@ieShared/components/ie-card/ie-card.component';
import { NotificationService } from '@ieRoot/app/core/services/notification.service';
import {
  NotificationResponseDto,
  UpdateNotificationInAppStatusDto,
} from 'shared-general-libs/dto/notification';
import { PaginatedResponseDto } from 'shared-general-libs/dto/pagination';
import { InAppStatus } from 'shared-general-libs/type/notification';
import { requestLoading } from '@ieRoot/app/core/helpers/request-loading.helper';
import { NzColDirective, NzRowDirective } from 'ng-zorro-antd/grid';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { TimeagoModule } from 'ngx-timeago';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NotificationRequestParams } from '@ieRoot/app/shared/interfaces/notifications.interface';
import { PageHeaderComponent } from '@ieShared/components/page-header/page-header.component';
import { IeAlertComponent } from '@ieShared/components/ie-alert/ie-alert.component';
import { IeNotificationListComponent } from '@ieShared/components/ie-notification-list/ie-notification-list.component';

@Component({
  selector: 'app-notification',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    IeBreadcrumbsComponent,
    IeButtonComponent,
    NgOptimizedImage,
    IeCardComponent,
    DatePipe,
    NzColDirective,
    NzRowDirective,
    NzAvatarModule,
    TimeagoModule,
    NzTabsModule,
    PageHeaderComponent,
    IeAlertComponent,
    IeNotificationListComponent,
  ],
  templateUrl: './notification.component.html',
  styleUrl: './notification.component.scss',
})
export class NotificationComponent implements OnInit {
  notifications: NotificationResponseDto[] = [];
  notificationsToday: NotificationResponseDto[] = [];
  notificationsYesterday: NotificationResponseDto[] = [];
  noDataFlag: boolean = false;
  tableParams: NotificationRequestParams = {
    page: 1,
    itemsPerPage: 10,
    inappStatus: '',
  };
  totalItems = 0;
  getNotificationsReq =
    requestLoading<PaginatedResponseDto<NotificationResponseDto>>();
  tabs = ['All', 'Unread'];

  constructor(private notificationService: NotificationService) {}
  ngOnInit(): void {
    this.getNotifications();
  }

  getNotifications(inappStatus?: string) {
    this.noDataFlag = false;
    if (inappStatus == 'Unread') {
      this.tableParams.inappStatus = 'UNREAD';
    } else {
      this.tableParams.inappStatus = '';
    }
    this.getNotificationsReq = this.notificationService.getNotifications(
      this.tableParams,
    );
    this.notifications = [];
    this.notificationsToday = [];
    this.notificationsYesterday = [];

    this.getNotificationsReq.response.subscribe((data) => {
      if (data.items.length === 0) {
        this.noDataFlag = true;
      }
      data.items.forEach((notification) => {
        if (this.compareWithToday(notification.created) === 0) {
          this.notificationsToday.push(notification);
        } else if (this.compareWithToday(notification.created) === 1) {
          this.notificationsYesterday.push(notification);
        } else {
          this.notifications.push(notification);
        }
      });
      this.totalItems = data.total;
    });
  }

  markAsRead(notification?: NotificationResponseDto) {
    const payload: UpdateNotificationInAppStatusDto = {
      inappStatus: InAppStatus.READ,
    };

    const markRequest =
      notification && notification.id
        ? this.notificationService.markAsRead(notification?.id, payload)
        : this.notificationService.markAsReadAll(payload);
    markRequest.response.subscribe(() => this.getNotifications());
  }

  compareWithToday(date: Date): number {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
    yesterday.setHours(0, 0, 0, 0);
    const givenDate = new Date(date);
    givenDate.setHours(0, 0, 0, 0);

    if (givenDate.getTime() === today.getTime()) {
      return 0;
    } else if (
      givenDate.getTime() >= yesterday.getTime() &&
      givenDate.getTime() <= yesterday.getTime()
    ) {
      return 1;
    } else {
      return -1;
    }
  }
}
